<template>
  <div class="app-container">
    <div>
      <el-tag
        :key="tag"
        v-for="tag in dynamicTags"
        style="margin-right:15px"
        closable
        :disable-transitions="false"
        @close="handleClose(tag)"
      >
        {{ tag }}
      </el-tag>
      <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="medium"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      >
      </el-input>
      <el-button v-else class="button-new-tag" size="medium" @click="showInput">
        添加
      </el-button>
    </div>
    <div style="margin-top: 40px">
      <el-button type="primary" @click="saveData" :loading="btnLoading">
        保存
      </el-button>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      btnLoading: false,
      inputValue: "",
      form: {
        sensitive_words: {
          value: "",
        },
      },
    };
  },
  created() {
    this.getDetail();
  },
  mounted() {},
  methods: {
    getDetail() {
      request({
        url: "/api/backend/settings/get",
        method: "get",
        params: {
          key: "sensitive_words",
        },
      }).then((response) => {
        if (response.data) {
          this.form.sensitive_words.value = response.data.value;
          this.dynamicTags =this.form.sensitive_words.value
        }
      });
    },
    saveData() {
      if (this.dynamicTags.length == 0) {
        this.$message.error("最少添加一个敏感词汇!");
        return;
      }
      this.btnLoading = true;
      this.form.sensitive_words.value=this.dynamicTags
      request({
        url: "/api/backend/settings/store",
        method: "post",
        data: this.form,
      })
        .then(() => {
          this.btnLoading = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getDetail();
          // this.$router.go(-1);
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-right: 10px;
}
// .button-new-tag {
//   margin-left: 10px;
// }
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>